import "./ForgotPasswordModal.css";
import { useState } from "react";
import { useParams } from "react-router-dom";
import SubmitButton from "../../SubmitButton/SubmitButton";
import InputField from "../../CredentialsInput/InputField";
import Env from "../../../images/checkEnvelope.svg";
import xIcon from "../../../icons/X.svg"

import * as translator from "../../../utils/translator";
import * as http from "../../../utils/http";
import useForm from '../../../customHooks/useForm';

export default function ForgotPasswordModal({ closeModal, navigationCallback,language }:
    { closeModal: any, navigationCallback: any,language:string }) {
    const { country } = useParams();
    const [showSubmitted, setShowSubmitted] = useState(false);
    const forgotPasswordFormAttributes = useForm({ email: "" }, language);

    const onSubmit = (e : React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        if (forgotPasswordFormAttributes.validateForm()) {
            http.postToServer(`confirmation-email`, { email: forgotPasswordFormAttributes.form.email, country, language, emailType:'resetPassword' })
                .then((response)=>{
                    setShowSubmitted(true);
                })
                .catch((error) => {
                    console.error("error forgot password: ", error);
                });
        }

    };

    return (
        <>
            <div
                className="forgotPasswordModal-background"
                onClick={(event) => {
                    closeModal();
                }}
            >
                {/*event.stopPropagation => so it won't close the modal when clicking on form fields */}
                <div className="forgotPasswordModal" onClick={(event) => event.stopPropagation()}>
                    <img onClick={() => closeModal()} className="forgotPasswordModal-x" src={xIcon} alt="x" />

                    {/* If submitted - show sent window */}
                    {showSubmitted ? (
                        <>
                            <img src={Env} alt="Thank you" className="" />
                            <p className="forgotPasswordModal-title-sent">
                                {translator.translate(
                                    language,
                                    "default",
                                    "ForgotPasswordModal/sent_reset_title"
                                )}
                            </p>
                            <p className="forgotPasswordModal-text">
                                {translator.translate(
                                    language,
                                    "default",
                                    "ForgotPasswordModal/sent_reset_text"
                                )}
                            </p>
                        </>
                    ) : (  /*   If not submitted - show reset password window    */
                        <form onSubmit={onSubmit}>
                            <p className="forgotPasswordModal-title">
                                {translator.translate(
                                    language,
                                    "default",
                                    "ForgotPasswordModal/password_reset_title"
                                )}
                            </p>
                            <p className="forgotPasswordModal-text">
                                {translator.translate(
                                    language,
                                    "default",
                                    "ForgotPasswordModal/password_reset_text"
                                )}
                            </p>

                            <InputField
                                value={forgotPasswordFormAttributes.form.email}
                                onChange={(value: string) => {
                                    forgotPasswordFormAttributes.updateForm("email", value)
                                }}
                                type="email"
                                placeholder={"E-Mail"}
                                language={language}
                                mandatory
                                errorMsg={forgotPasswordFormAttributes.errorMessagePerField.email}
                                onBlur={() => forgotPasswordFormAttributes.onFieldBlur("email")}
                                touched={forgotPasswordFormAttributes.touched.email}
                            />

                            <SubmitButton
                                id={"submit-button"}
                                isEnabledState={true}
                                language={language}
                                onSubmit={onSubmit}
                                width={"100%"}
                                placeholder={translator.translate(
                                    language,
                                    "default",
                                    "ForgotPasswordModal/submit"
                                )}
                            />
                        </form>
                    )}
                </div>
            </div>
        </>
    );
}
