import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { clientConfig } from "../config";

const init = () => {
  Bugsnag.start({
    logger: null,
    releaseStage: clientConfig.ENV,
    onError: (event) => {
      //event.app.releaseStage = clientConfig.ENV;
    },
    apiKey: clientConfig.BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    appVersion: clientConfig.VERSION,
  });
};

const addBreadcrumbs = (event, data) => {
  try {
    if (!event.breadcrumbs) {
      event.breadcrumbs = [];
    }

    // adding breadcrubms from the logger
    if (data.breadcrumbs) {
      data.breadcrumbs.forEach((breadcrumb) => {
        event.breadcrumbs.push({
          type: "state",
          name: breadcrumb.message,
          metaData: breadcrumb.data,
          timestamp: new Date(breadcrumb.timestamp).toISOString(),
        });
      });
    }

    let arrivals = window.eventsTracking.getData().arrivals;

    arrivals.forEach(currArrival=>{
        event.breadcrumbs.push({
            type: "state",
            name: "Arrival",
            metaData: {url:currArrival.url,ref:currArrival.ref},
            timestamp: new Date(currArrival.time * 1000).toISOString(),
          });
    })

    event.breadcrumbs = event.breadcrumbs.sort((a, b) => {
        return (
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        );
      });


  } catch (err) {
    console.warn("error adding breadcrumbs", err);
  }
};

const hookNotificationError = () => {
  window.clientLogger &&
    window.clientLogger.addErrorNotification((data) => {
      let error;
      let args = Array.from(data.args);
      let message = args.find((arg) => typeof arg === "string");
      error = args.find((arg) => arg instanceof Error);

      let metaData = args.filter((arg) => arg !== message && arg !== error);
      if (!error) {
        error = new Error(message);
      }
      Bugsnag.notify(error, function (event) {
        event.severity = data.level;
        event.context = message;

        let email = window.eventsTracking.getData("email");
        let customerID = window.eventsTracking.getData("customerID");

        if (customerID) {
          event.setUser(customerID, email);
        }

        if (metaData.length) {
          event.addMetadata("args", metaData);
        }

        event.addMetadata("user", {uid: window.eventsTracking.getData().uid});

        addBreadcrumbs(event, data);
      });
    });
};

export const start = () => {
  init();

  hookNotificationError();

};
