import { createContext, useEffect, useState, useContext } from "react";
import * as http from "../../../utils/http";
import HeaderController from "../../../components/Headers/HeaderController";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "./ChooseTreatment.css";
import DrugChoicePanel from "../components/DrugChoicePanel/DrugChoicePanel";
import DrugSelectionSidePanel from "../components/DrugSelectionSidePanel/DrugSelectionSidePanel";
import * as translator from "../../../utils/translator";
import LoadingModal from "../../../components/Modals/LoadingModal/LoadingModal";
import ServerError from "../../../components/ServerError/ServerError";
import { MainTracker } from "../../../PixelTrackers/MainTracker";
import Footer from "../../../components/Footer/Footer";
import StickyNotification from "../../../components/StickyNotification/StickyNotification";
import { getDefaultProductItem } from "../helpers";
import { CatalogSettingContext,SubscriptionModalContext } from "../ChooseTreatmentRouter";
import {submitOverrideContext} from "../../../components/Modals/MyAccountChangeSubscriptionMedicationModal/MyAccountChangeSubscriptionMedicationModal";


let currentlySelectedDrugTitle = "";
let currentDosage = "";
let pricesLogistics;
let noPreferenceDefaultItem;

//for now only supports images, product_id, and item_title
export const CartDataContext = createContext();
export const StickyNotificationContext = createContext();
export const DefaultProductContext = createContext();

const ChooseTreatmentPage = () => {
  const catalogSettings = useContext(CatalogSettingContext);
  const subscriptionModalContext = useContext(SubscriptionModalContext);
  const { setCatalogSettings, subscriptionData, subscriptionDiscount } = useContext(submitOverrideContext); //for coming from the change medication modal (needs context since not called from other providers)
  let currencySign = '€'
  let mention
  if(subscriptionData){
    mention = subscriptionData.catalog_mention
    currencySign =  subscriptionData.currencySign
  }
  const [searchParams] = useSearchParams();
  const redirectError = searchParams.get("ec"); //ec means error code
  let { country, language, category } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [drugImagePath, setDrugImagePath] = useState("");
  const [noPreference, setNoPreference] = useState(false);

  //tracks every single Product relevant to this flow/page
  const [listOfAllProducts, setListOfAllProducts] = useState([]);

  //tracks the presented titles of all the products (e.g Viagra, Cialis, ...)
  const [listOfProductsTitles, setListOfProductsTitles] = useState([]);
  const [currentListOfDosagesStrings, setCurrentListOfDosagesStrings] =
    useState([]);
  const [listOfProductItems, setListOfProductItems] = useState([]);
  const [defaultDosagesByProductTitle, setDefaultDosagesByProductTitle] =
    useState({});
  const [minPricesByProductTitle, setMinPricesByProductTitle] = useState({});
  const [productFullTitles, setProductFullTitles] = useState([]);
  const [currentlySelectedDosageTitle, setCurrentlySelectedDosageTitle] =
    useState("");
  const [
    productItemSubscriptionPlansByID,
    setProductItemSubscriptionPlansByID,
  ] = useState({});

  const [currentSelectedProductItemIndex, setCurrentSelectedProductItemIndex] =
    useState(null);

  const [showStickyNotification, setShowStickyNotification] = useState({
    show: false,
    title: "",
    content: "",
  });

  const handleErrorNotification = (title, content) => {
    if (!title && !content) {
      setShowStickyNotification({ show: false, title: "", content: "" });
    } else {
      setShowStickyNotification({ show: true, title, content });
    }
  };

  const navigate = useNavigate();
  const navigationCallback = (pathname) => {
    navigate({
      pathname,
    });
  };

  //dnis is short for dosage not in stock
  //nss - no subscription stock
  let url
  if(mention){ //display choose treatment with no cart (i.e change subscription medication)
    language = 'de'
    url = `products-by-catalog/${country}/${language}/${mention}/false`
  }else{
    url = `products/${country}/${language}/${category}/false`
  }

  useEffect(() => {
    if (redirectError == "dnis") {
      subscriptionModalContext.showSubscriptionModalCallback(false);
      handleErrorNotification(
        translator.translate(
          language,
          "default",
          "StickyNotification/danger_title"
        ),
        translator.translate(
          language,
          "default",
          "StickyNotification/product_not_in_stock"
        )
      );
    } else if (redirectError == "nss") {
      subscriptionModalContext.showSubscriptionModalCallback(false);
      handleErrorNotification(
        translator.translate(
          language,
          "default",
          "StickyNotification/danger_title"
        ),
        translator.translate(
          language,
          "default",
          "StickyNotification/product_not_in_stock_for_subscription"
        )
      );
    }

    //catalog choose treatment settings
      http
        .getFromServer(url, [
          http.MIDDLEWARES.CUSTOMER_ID,
          http.MIDDLEWARES.CART_ORDER
        ])
        .then((response) => {
          let productsData = response.data.productsData;
          if(subscriptionData){
            setCatalogSettings(response.data.catalogSettings);

            // alter min prices with currency rate
            Object.entries(productsData.minPrices).forEach(([key, value]) => { 
              productsData.minPrices[key] = ((value * subscriptionData.currencyRate)-subscriptionDiscount).toFixed(2);
            });

            // alter price logistics with currency rate
            Object.entries(productsData.pricesLogistics).forEach(([key, value])=>{ //run on products
              Object.entries(value).forEach(([key1, value1]) => { //nested run on product items (dosages)
                productsData.pricesLogistics[key][key1].price = ((value1.price * subscriptionData.currencyRate)-subscriptionDiscount).toFixed(2);
              });
            })

            // alter actual prices displayed with currency rate
            Object.entries(productsData.products).forEach(([key, value])=>{ //run on products
              Object.entries(value).forEach(([key1, value1]) => { //nested run on product items per products (dosages)
                value1.forEach((quantity, index)=>{ //nested run on quantities per dosage
                  productsData.products[key][key1][index].price = ((quantity.price * subscriptionData.currencyRate)-subscriptionDiscount);
                })
              });
            })
          }
         setLoading(false);
          setListOfAllProducts(productsData.products);

          if (country.toUpperCase() === "FR") {
            noPreferenceDefaultItem = getDefaultProductItem(
              productsData.products
            );
          }

          setListOfProductsTitles(Object.keys(productsData.products));
          setDefaultDosagesByProductTitle(productsData.defaultDosages);
          setMinPricesByProductTitle(productsData.minPrices);
          currentlySelectedDrugTitle = Object.keys(productsData.products)[0];
          currentDosage = Object.keys(
            productsData.products[currentlySelectedDrugTitle]
          )[0];

          setCurrentListOfDosagesStrings(
            Object.keys(productsData.products[currentlySelectedDrugTitle])
          );
          setListOfProductItems((old) => {

            const packets = productsData.products[currentlySelectedDrugTitle][currentDosage]

            if (catalogSettings.allowPrescriptionForOutOfStock) {
              return packets.map((packet) => {return {...packet, in_stock : 1, original_in_stock : packet.in_stock}})
            } else {
              return packets.map((packet) => {return {...packet, original_in_stock : packet.in_stock}})
            }
          }
          );
          setProductFullTitles(productsData.drugFullTitles);
          pricesLogistics = productsData.pricesLogistics;
          setProductItemSubscriptionPlansByID(productsData.proudctItemPlans);
          setDrugImagePath(productsData.drugImagesPath);
        })
        .catch((error) => {
          console.error("failed get response from server", error);
          setLoading(false);
          setError(500);
        });
  }, []);

  const changeCurrentlySelectedProductItemIndex = (newProductItemIndex) => {
    setCurrentSelectedProductItemIndex(newProductItemIndex);
  };

  const changeDosageList = (newDosageList) => {
    setCurrentListOfDosagesStrings(newDosageList);
  };

  const changeListOfProductItems = (newPacketsList) => {
    if (catalogSettings.allowPrescriptionForOutOfStock) {
      newPacketsList = newPacketsList.map((packet) => {return {...packet, in_stock : 1, original_in_stock : packet.in_stock}})
    } else {
      newPacketsList = newPacketsList.map((packet) => {return {...packet, original_in_stock : packet.in_stock}})
    }
    setListOfProductItems(newPacketsList);
  };



  const changeSelectedDrug = (drugTitle) => {
    MainTracker.superProperties({ "Product Name": drugTitle });
    MainTracker.track("click", "Click change Product Name");
    currentlySelectedDrugTitle = drugTitle;

    if (currentlySelectedDrugTitle === "no-preference") {
      setNoPreference(true);

      return;
    }

    setNoPreference(false);

    if (defaultDosagesByProductTitle[currentlySelectedDrugTitle]) {
      setCurrentlySelectedDosageTitle(
        defaultDosagesByProductTitle[currentlySelectedDrugTitle]
      );
      changeDosageList(
        Object.keys(listOfAllProducts[currentlySelectedDrugTitle])
      );
      changeSelectedDosage(
        defaultDosagesByProductTitle[currentlySelectedDrugTitle]
      );
      changeCurrentlySelectedProductItemIndex(null);
    } else {
      let alternativeDosage = Object.keys(
        listOfAllProducts[currentlySelectedDrugTitle]
      )[0];

      setCurrentlySelectedDosageTitle(alternativeDosage);
      changeDosageList(
        Object.keys(listOfAllProducts[currentlySelectedDrugTitle])
      );
      changeSelectedDosage(alternativeDosage);
      changeCurrentlySelectedProductItemIndex(null);
    }
  };

  const changeSelectedDosage = (newDosage) => {
    setCurrentlySelectedDosageTitle(newDosage);
    setListOfProductItems(
      listOfAllProducts[currentlySelectedDrugTitle][newDosage]
    );
  };

  return (
    <>
      {loading && <LoadingModal />}

      {error && (
        <>
          <HeaderController showSteps={false} stage={"Questionnaire"} />
          <ServerError status={error} />
        </>
      )}

      {!error && !loading && (
        <>
          <StickyNotification
            showNotification={showStickyNotification.show}
            title={showStickyNotification.title}
            onClick={(falseValue) => setShowStickyNotification(falseValue)}
            content={showStickyNotification.content}
          />

          {!mention && <HeaderController language={language} stage="Treatment" />} {/*shows header in normal flow but not in modal(csm) flow*/}
          <div className="selectTreatment-body-container">
            <div className="selectTreatment-container">
              <h1
                style={
                  country.toUpperCase() !== "FR" && !mention
                    ? { margin: "0px 95px 60px" }
                    : null
                }
                className="selectTreatment-title"
              >
                {translator.translate(
                  language,
                  "default",
                  "ChooseTreatment/ChooseTreatmentTitle"
                )}
              </h1>

              {/*shows only in change subscription medication flow*/}
              {mention && ( 
                <p className="selectTreatment-subtitle">
                  {translator.translate(
                    language,
                    "default",
                    "ChooseTreatment/ChooseTreatmentSubTitle"
                  )}
                </p>
              )}

              {country.toUpperCase() === "FR" ? (
                <p className="selectTreatment-subtitle">
                  {translator.translate(
                    language,
                    "default",
                    "ChooseTreatment/ChooseTreatmentSubTitle"
                  )}
                </p>
              ) : null}

              <div className="panels-container">
                {/* start of context */}
                <StickyNotificationContext.Provider
                  value={handleErrorNotification}
                >
                  <CartDataContext.Provider
                    value={{
                      drugImagePath,
                      productItemSubscriptionPlansByID,
                    }}
                  >
                    
                      <DefaultProductContext.Provider
                        value={noPreferenceDefaultItem}
                      >
                        <DrugChoicePanel
                          currencySign={currencySign}
                          navigationCallback={navigationCallback}
                          products={listOfAllProducts}
                          currentSelectedProductItemIndex={
                            currentSelectedProductItemIndex
                          }
                          changeCurrentlySelectedProductItemIndex={
                            changeCurrentlySelectedProductItemIndex
                          }
                          productItemSubscriptionPlans={
                            productItemSubscriptionPlansByID
                          }
                          selectedDosage={currentlySelectedDosageTitle}
                          pricesLogistics={pricesLogistics}
                          language={language}
                          landingPage={false}
                          drugFullNames={productFullTitles}
                          listOfPackets={listOfProductItems}
                          selectedDrug={currentlySelectedDrugTitle}
                          defaultDosages={defaultDosagesByProductTitle}
                          listOfDosages={currentListOfDosagesStrings}
                          changeDosage={changeSelectedDosage}
                          changePacketsList={changeListOfProductItems}
                          listOfProducts={listOfAllProducts}
                          productKeys={listOfProductsTitles}
                          changeDrugCallback={changeSelectedDrug}
                          minPrices={minPricesByProductTitle}
                          width={390}
                          height={60}
                          productsContainer={"70%"}
                          enableNoPreferenceOption={
                            catalogSettings.isNoPrefereceTreatmentEnabled
                          }
                          country={country}
                        />

                        <DrugSelectionSidePanel
                          currencySign={currencySign}
                          selectedDosage={currentlySelectedDosageTitle}
                          currentSelectedProductItemIndex={
                            currentSelectedProductItemIndex
                          }
                          changeCurrentlySelectedProductItemIndex={
                            changeCurrentlySelectedProductItemIndex
                          }
                          productItemSubscriptionPlans={
                            productItemSubscriptionPlansByID
                          }
                          navigationCallback={navigationCallback}
                          pricesLogistics={pricesLogistics}
                          language={language}
                          drugFullNames={productFullTitles}
                          hideOnResize={true}
                          products={listOfAllProducts}
                          listOfPackets={listOfProductItems}
                          selectedDrug={currentlySelectedDrugTitle}
                          defaultDosages={defaultDosagesByProductTitle}
                          listOfDosages={currentListOfDosagesStrings}
                          changeDosage={changeSelectedDosage}
                          changePacketsList={changeListOfProductItems}
                          noPreference={noPreference}
                        />
                      </DefaultProductContext.Provider>
                  </CartDataContext.Provider>
                </StickyNotificationContext.Provider>
                {/* end of context */}
              </div>
            </div>
          </div>
          {!mention && <Footer showDiscountDisclaimer={true} page="ChooseTreatment" language={language} />} {/*shows footer in normal flow but not in modal*/}
        </>
      )}
    </>
  );
};

export default ChooseTreatmentPage;
