import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import "./DrugChoicePanel.css";
import NoPreferenceTreatmentCard from "../../../../components/NoPreferenceTreatmentCard/NoPreferenceTreatmentCard";
import DrugDisplayCard from "../DrugDisplayCard/DrugDisplayCard";
import DefaultProductCard from "../../components/DefaultProductCard/DefaultProductCard";
import * as http from "../../../../utils/http";
import { CatalogSettingContext } from "../../ChooseTreatmentRouter";

const DrugChoicePanel = ({
  currencySign,
  selectedDosage,
  products,
  currentSelectedProductItemIndex,
  changeCurrentlySelectedProductItemIndex,
  productItemSubscriptionPlans,
  pricesLogistics,
  language,
  currentProductDescription,
  drugImagePath,
  selecetedDrugState,
  landingPage,
  drugFullNames,
  listOfPackets,
  changeDrugCallback,
  selectedDrug,
  defaultDosages,
  listOfDosages,
  changeDosage,
  changePacketsList,
  listOfProducts,
  productKeys,
  minPrices,
  width,
  height,
  productsContainer,
  navigationCallback,
  enableNoPreferenceOption = false,
  country
}) => {
  const [selectedDrugID, setSelectedDrugID] = useState(0);
  const [defaultProductName, setDefaultProductName] = useState(null);
  const [defaultProductIsInStock, setDefaultProductIsInStock] = useState(false);
  const [searchParams] = useSearchParams();
  const [noPreferenceDrugName, setNoPreferenceDrugName] = useState('');

  const catalogSettings = useContext(CatalogSettingContext);
  const hidePrices = catalogSettings.forceHidePrices ? true : false;


  const catalogID = searchParams.get("catalog_id");

  const getFirstInStockItem = () => {
    let found = false;
    let foundIndex = null;
    let drugName = "";

    if (defaultProductName) {
      foundIndex = -1;
      drugName = defaultProductName;
    } else {
      productKeys.forEach((drugTitle, index) => {
        if (!found && !isDrugOutOfStock(drugTitle)) {
          found = true;
          foundIndex = index;
          drugName = drugTitle;
        }
      });
    }

    if (foundIndex) {
      changeDrugCallback(drugName);
      return foundIndex;
    } else {
      return 0;
    }
  };

  const isDrugOutOfStock = (drugTitle) => {
    let outOfStock = true;
    Object.keys(products[drugTitle]).forEach((dosageString) => {
      products[drugTitle][dosageString].forEach((productItem) => {
        if (productItem.in_stock === 1 || catalogSettings.allowPrescriptionForOutOfStock) {
          outOfStock = false;
        }
      });
    });

    return outOfStock;
  };

  useEffect(() => {
    let firstAvailableDrugIndex = getFirstInStockItem();
    setSelectedDrugID(firstAvailableDrugIndex);

    http
      .getFromServer(`products/${catalogID}/${language}`, [
        http.MIDDLEWARES.CUSTOMER_ID,
        http.MIDDLEWARES.CART_ORDER
      ])
      .then((resp) => {
        let defaultProd = resp.data;
        //if the product exists
        if (products[defaultProd]) {
          //if its in stock, select it
          if (!isDrugOutOfStock(defaultProd)) {
            setSelectedDrugID(-1);
            changeDrugCallback(defaultProd);
            setDefaultProductIsInStock(true);
          }
          setDefaultProductName(defaultProd);
        } else {
          setNoPreferenceDrugName(productKeys[0]);
          if (enableNoPreferenceOption) {
            setSelectedDrugID(-1);
            changeDrugCallback('no-preference')
          }
        }
      })
      .catch((error) => {
        console.error('error in getfromServer - DrugChoicePanel',error);
      });
  }, []);

  return (
    <ul style={{ width: productsContainer }} className="products-containter">
      {/* viable only for france for now (?) */}
      {enableNoPreferenceOption && noPreferenceDrugName && (
        <NoPreferenceTreatmentCard
          setSelectedDrugID={setSelectedDrugID}
          changeDrugCallback={changeDrugCallback}
          index={-1}
          width={width}
          height={height}
          selectedDrugID={selectedDrugID}
          isDisabled={false}
          minPrices={minPrices}
          language={language}
        />
      )}

      {defaultProductName && (
        <DefaultProductCard
          key={-1}
          currencySign={currencySign}
          drugName={defaultProductName}
          setSelectedDrugID={setSelectedDrugID}
          changeDrugCallback={changeDrugCallback}
          index={-1}
          width={width}
          height={height}
          selectedDrugID={selectedDrugID}
          isDisabled={!defaultProductIsInStock}
          minPrices={minPrices}
          language={language}
          navigationCallback={navigationCallback}
          selectedDosage={selectedDosage}
          currentSelectedProductItemIndex={currentSelectedProductItemIndex}
          productItemSubscriptionPlans={productItemSubscriptionPlans}
          pricesLogistics={pricesLogistics}
          drugFullNames={drugFullNames}
          hideOnResize={false}
          products={listOfProducts}
          listOfPackets={listOfPackets}
          selectedDrug={selectedDrug}
          defaultDosages={defaultDosages}
          listOfDosages={listOfDosages}
          changeDosage={changeDosage}
          changePacketsList={changePacketsList}
          landingPage={landingPage}
          changeCurrentlySelectedProductItemIndex={
            changeCurrentlySelectedProductItemIndex
          }
          hideable={false}
          drugDescription={currentProductDescription}
          drugImagePath={drugImagePath}
          selecetedDrugState={selecetedDrugState}
          showPrice={(country.toUpperCase() === 'FR' || hidePrices) ? false : true}
        />
      )}

      {productKeys
        .filter((drugName) => drugName !== defaultProductName)
        .map((drugName, index) => {
          let isDisabled = isDrugOutOfStock(drugName);

          return (
            <DrugDisplayCard
              key={index}
              currencySign={currencySign}
              drugName={drugName}
              setSelectedDrugID={setSelectedDrugID}
              changeDrugCallback={changeDrugCallback}
              index={index}
              width={width}
              height={height}
              selectedDrugID={selectedDrugID}
              isDisabled={isDisabled}
              minPrices={minPrices}
              language={language}
              country={country}
              navigationCallback={navigationCallback}
              selectedDosage={selectedDosage}
              currentSelectedProductItemIndex={currentSelectedProductItemIndex}
              productItemSubscriptionPlans={productItemSubscriptionPlans}
              pricesLogistics={pricesLogistics}
              drugFullNames={drugFullNames}
              hideOnResize={false}
              products={listOfProducts}
              listOfPackets={listOfPackets}
              selectedDrug={selectedDrug}
              defaultDosages={defaultDosages}
              listOfDosages={listOfDosages}
              changeDosage={changeDosage}
              changePacketsList={changePacketsList}
              landingPage={landingPage}
              changeCurrentlySelectedProductItemIndex={
                changeCurrentlySelectedProductItemIndex
              }
              hideable={false}
              drugDescription={currentProductDescription}
              drugImagePath={drugImagePath}
              selecetedDrugState={selecetedDrugState}
              showPrice={(country.toUpperCase() === 'FR' || hidePrices) ? false : true}
            />
          );
        })}
    </ul>
  );
};

export default DrugChoicePanel;
