import React from 'react'
import { MdInfoOutline } from "react-icons/md";
import * as translator from "../../../utils/translator";

const ERezeptDisclaimer: React.FC<{languageID: string}> = ({languageID}) => {
    return (
        <div id='erezept-disclaimer' className='p-2 bg-[#E8F1FF] rounded-md flex items-start text-lg gap-1 w-full'>
            <MdInfoOutline size={60} className='text-[#4B91FA] mx-1 h-6' />
            <div>
                <p className='text-[#4B91FA] font-semibold text-sm'>{translator.translate(languageID, "default", "ChooseTreatment/e-recipe-significance")}</p>
            </div>
        </div>
    )
}

export default ERezeptDisclaimer
