import { useContext } from "react";
import OrderOTCAddons from "../../../../components/OrderOTCAddons/OrderOTCAddons";
import OrderWindow from "../../../../components/OrderWindow/OrderWindow";
import CouponWidget from "../CouponWidget/CouponWidget";
import OrderSummary from "../../../../components/OrderSummary/OrderSummary";
import { ShippingDataContext } from "../../Shipping";
import DigitalCannabisIDWidget from "../../../../components/DigitalCannabisIDWidget/DigitalCannabisIDWidget";

const COUPON = "coupon";
const SUBSCRIPTION = "subscription";
const SUBSCRIPTION_MODAL = "subscription_modal";

const OrderInfoInterface = ({
  orderWindowDetails,
  subscriptionDiscount,
  couponDiscount,
  discountData,
  selectedPlanIndex,
  showCouponConfirmed,
  showCouponInput,
  showSubscriptionPlans,
  updateDispatch,
  countryID,
  prescriptionOnly,
  autoBonusValue,
  OTCAddonsAttributes,
  isSelfPickup

}) => {
  
  // orderWindowDetails.treatmentFee + couponDiscount = original treatmentFee before bonuses
  const shouldHideCouponWidget = subscriptionDiscount || autoBonusValue || (orderWindowDetails.treatmentFee + Number(couponDiscount) === 0);
  const shippingData = useContext(ShippingDataContext);
  const {cannabisIDState, setCannabisIDState} = shippingData;
  const multiplier = shippingData.currencyRates[shippingData.destinationCountry].rate;

  return (
    <>
      {/* The prescription (Rx) Product  */}
      <OrderWindow
        catalogSettings={orderWindowDetails.catalogSettings}
        itemImageUrl={orderWindowDetails.itemImageUrl}
        itemTitle={orderWindowDetails.itemTitle}
        Price={orderWindowDetails.Price * multiplier}
        serviceFee={orderWindowDetails.serviceFee * multiplier}
        isSubsidized={orderWindowDetails.isSubsidized}
        shippingFee={orderWindowDetails.shippingFee * multiplier}
        itemQuantity={orderWindowDetails.itemQuantity}
        treatmentFee={orderWindowDetails.treatmentFee * multiplier}
        subscriptionPlans={orderWindowDetails.subscriptionPlans}
        subscriptionDiscount={subscriptionDiscount * multiplier}
        didntChooseSubscriptionInCT= {orderWindowDetails.didntChooseSubscriptionInCT}
        changeSubscriptionDiscount={(value) =>
          updateDispatch(SUBSCRIPTION, value)
        }
        couponDiscount={couponDiscount}
        openSubscriptionModal={(boolValue) =>
          updateDispatch(SUBSCRIPTION_MODAL, boolValue)
        }
        changeCouponDiscount={(value) => updateDispatch(COUPON, value)}
        showSubscriptionPlans={showSubscriptionPlans}
        selectedPlanIndex={selectedPlanIndex}
        updateDispatch={updateDispatch}
        cartID={orderWindowDetails.cartID}
        autoBonusValue={autoBonusValue * multiplier}
        prescriptionOnly={prescriptionOnly}
        setRxProductFinalPrice={shippingData.setRxProductFinalPrice}
        isSelfPickup={isSelfPickup}
        isCannabisIDAddedToOrder={cannabisIDState.isCannabisIDAddedToOrder}
      />

      {/* Selected OTC Addons of the order -> ONLY FOR DE*/}
      {
         countryID.toUpperCase() === "DE" && shippingData?.OTCAddonsProducts.length > 0 ? //if catalog has OTC Addons, show button
         <OrderOTCAddons
            title={"Nicht rezeptpflichtige Medikamente"} //TODO: Translations
            selectedOtcAddons={OTCAddonsAttributes.getSelectedAddons()}
            OTCAddonsAttributes={OTCAddonsAttributes}
            shouldShowOtcAddonsModalButton={true}
            currencyRate={shippingData.currencyRates[shippingData.destinationCountry].rate}
            currencySign={shippingData.currencySign} 
         />
         :
         null
      }

      {/* Summary of the RX (Prescription product) + OTC Products -> ONLY FOR DE */}
      {
        countryID.toUpperCase() === "DE" && shippingData?.OTCAddonsProducts.length > 0 ? //if catalog has OTC Addons, show button
        <OrderSummary
          rxProductFinalPrice={shippingData.rxProductFinalPrice} //no need to multiply by currencyRate because it's basically orderWindowDetails.Price which is already multiplied
          otcAddonsTotalPrice={OTCAddonsAttributes.getTotalPrice() * shippingData.currencyRates[shippingData.destinationCountry].rate}
          currencySign={shippingData.currencySign}
          countryID={countryID}
        />
        :
        null
      }


      {/* Digital Cannabis ID Widget  */}
      { cannabisIDState.showCannabisIDWidget ?
        < DigitalCannabisIDWidget
          onTextChange={(value) => setCannabisIDState({ ...cannabisIDState, personalIDNumber: value, errorMsg: "" })}
          inputValue={cannabisIDState.personalIDNumber}
          onToggle={(isToggleEnabled) => shippingData.setCannabisIDState({ ...cannabisIDState, isCannabisIDAddedToOrder: isToggleEnabled })}
          isToggleEnabled={cannabisIDState.isCannabisIDAddedToOrder}
          inputErrorMsg={cannabisIDState.errorMsg}
          setRef={(ref) => setCannabisIDState({ ...cannabisIDState, widgetRef:ref })}
        />
        :
        null
      }



      { shouldHideCouponWidget ?
        null
        :  
        <CouponWidget
          couponDiscount={couponDiscount}
          treatmentFee={orderWindowDetails.treatmentFee}
          changeCouponDiscount={(discount) => updateDispatch(COUPON, discount)}
          showCouponConfirmed={showCouponConfirmed}
          showCouponInput={showCouponInput}
          discountData={discountData}
          updateDispatch={updateDispatch}
          orderTotal={
            orderWindowDetails.Price +
            orderWindowDetails.treatmentFee -
            subscriptionDiscount
          }
        />
      }

    </>
  );
};

export default OrderInfoInterface;
