import React from 'react';
import "./QuantityControl.css"

type QuantityControlProps = {
    quantity:number
    decrementButtonOnClick: () => void
    incrementButtonOnClick: () => void
}


const QuantityControl:React.FC<QuantityControlProps> = ({quantity,decrementButtonOnClick,incrementButtonOnClick}) => {
  return (
    <div className="quantity-control-container">
        <button onClick={()=> decrementButtonOnClick()} id='quantity-control-button-decrement' className={`quantity-control-button`}>-</button>
        <span className='quantity-control-quantity'>{quantity}</span>
        <button onClick={()=> incrementButtonOnClick()} id='quantity-control-button-increment' className={`quantity-control-button`}>+</button>
    </div>
  )
}

export default QuantityControl