import { useEffect, useMemo, useState } from "react";
import "../Thankyou/Thankyou.css";
import WireDetailsWidget from "./components/WireDetailsWidget/WireDetailsWidget";
import { useParams, useSearchParams } from "react-router-dom";
import { Fragment } from "react";
import HeaderController from "../../components/Headers/HeaderController";
import SubmitButton from "../../components/SubmitButton/SubmitButton";
import * as translator from "../../utils/translator";
import ThankyouStepsWidget from "../Thankyou/components/ThankyouStepsWidget/ThankyouStepsWidget";
import ThankyouMainTitleWidget from "../Thankyou/components/ThankyouMainTitleWidget/ThankyouMainTitleWidget";
import { MainTracker } from "../../PixelTrackers/MainTracker";
import { clearCart } from "../../reducers/CartOrderReducer";
import * as http from "../../utils/http";
import * as SessionStorageWrapper from "../../utils/SessionStorageWrapper";
import Trustedshops from "../../components/Trustedshops/Trustedshops";
import ServerError from "../../components/ServerError/ServerError";
import LoadingModal from "../../components/Modals/LoadingModal/LoadingModal";

const WireTransferPage = () => {
  const [searchParams] = useSearchParams();
  const orderPrice = Number(searchParams.get("op"));
  const currencyRate = Number(searchParams.get("cr"));
  const currencySign = searchParams.get("cs");
  const params = useParams();
  const hashID = params.order_hash_id;
  const language = params.language;
  const country = translator.getCountryByLanguage(language);

  let thankyouHash = searchParams.get("thankyouHash");
  const isOrderOnlyPrescription = searchParams.get("onlyPrescription") === "1" ? true : false;
  const isERecipe = searchParams.get("erecipe") === "1" ? true : false;
  const [bankDetails, setBankDetails] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isEPrescriptionOnly, setIsEPrescriptionOnly] = useState(false);

  const relocationString = useMemo(() => {

    if (isEPrescriptionOnly) {
      return `/${language}/account/my-orders/${hashID}/details#pharmacy-selection-container`
    }

    return `/${language}/account/my-orders`
  },
    [isEPrescriptionOnly, language, country])

  const btnTextTranslationKey = isEPrescriptionOnly ? "page/button_text_e_prescription" : "page/button_text";


  useEffect(() => {
    window.addEventListener("popstate", (e) => {
      e.preventDefault();
      window.location.replace(relocationString);
    });
  });

  useEffect(() => {
    if (thankyouHash && hashID) {
      if (SessionStorageWrapper.getItem("last_thankyou_order") !== hashID) {

        http
          .getFromServer(`thank_you/${country}/${hashID}/${thankyouHash}`, [])
          .then((response) => {

            if (response.data.isEPrescriptionOnly) {
              setIsEPrescriptionOnly(true);
            }

            MainTracker.superProperties(response.data.ConversionData.tracking)
            MainTracker.track(
              "custom",
              "ViewThankyouPage",
              response.data.ConversionData
            );

            SessionStorageWrapper.setItem("last_thankyou_order", hashID);

            clearCart();
          })
          .catch((error) => {
            console.error("Thanyou Page getFromServer error", error);
          })
      }
    }

    // get bank details from server
    http
      .getFromServer(`bank_details/${country}`, [])
      .then((response) => {

        setBankDetails(response.data.bankDetails);
      })
      .catch((err) => {
        console.error("get bank details error", err);
        setError(true)
      })
      .finally(() => {
        setLoading(false);
      });


  }, []);

  if (error) {
    return <ServerError status={500} />;
  } else if (loading) {
    return <LoadingModal />;
  } else {
    return (
      <>
        <Trustedshops
          isThankyouPage={true}
          language={language}
        />
        <HeaderController
          language={language}
          showSteps={false}
          stage={"Treatment"}
        />
        <div className="body-container">
          <div className="thankyou-container">
            <ThankyouMainTitleWidget
              thankyouTitle={translator.translate(
                language,
                "thankyou",
                "page/title"
              )}
              thankyouSubTitle={translator.translate(
                language,
                "wire",
                "page/subtitle"
              )}
            />

            <p className="disclaimer">
              {" "}
              {translator.translate(
                language,
                "thankyou",
                "page/disclaimer_text"
              )}{" "}
            </p>
            <WireDetailsWidget
              bankDetailsString={bankDetails}
              orderPrice={orderPrice * currencyRate}
              currencySign={currencySign}
              hashID={hashID || ""}
              language={language || ""}
            />

            <div className="thankyou-steps-container">
              <p className="thankyou-steps-title">
                {translator.translate(language, "thankyou", "page/steps_title")}
              </p>

              <ThankyouStepsWidget
                isEPrescriptionOnly={isEPrescriptionOnly}
                language={language}
                isOrderOnlyPrescription={isOrderOnlyPrescription}
                isERecipe={isERecipe}
                page={"wire"}
              />

              <SubmitButton
                id={"submit-button"}
                language={language}
                placeholder={translator.translate(
                  language,
                  "thankyou",
                  btnTextTranslationKey
                )}
                onSubmit={() => {
                  window.location.replace(relocationString);
                }}
              />
            </div>
          </div>
          <div id="trustbadgeCustomCheckoutContainer"></div>
        </div>
      </>
    );
  }
};

export default WireTransferPage;
