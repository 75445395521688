const FbTracker = require("./FbTracker");
const GtmTracker = require("./GtmTracker");
const WisePopTracker = require("./WisepopsTracker");
const MixPanelTracker = require("./MixPanelTracker");
const HubspotTracker = require("./HubspotTracker");
const VwoTracker = require("./VwoTracker");

let initialized = false
export const MainTracker = {
    init: () =>{
      if (initialized){
        console.warn('Main Tracker init more than once')
      }
      else{
        GtmTracker.init()
        MixPanelTracker.init()
        initialized = true
      }
    },
    identify: (uuid,email) => {
        MixPanelTracker.identify(uuid);
        HubspotTracker.identify(email)
    },
    alias: (id) => {
        MixPanelTracker.alias(id);
    },
    superProperties: (props) => {
      try{
        MixPanelTracker.superProperties(props);
      }
      catch(err){
        console.error('MainTracker error setting superProerties',err)
      }
    },
    track: (eventType,eventName, data) => {
      MixPanelTracker.track(eventType,eventName, data);
      GtmTracker.track(eventType,eventName,data?.tagManager);
      FbTracker.track(eventType,eventName,data?.facebook);
      HubspotTracker.track(eventType,eventName,data);
      VwoTracker.track(eventType,eventName,data);
      WisePopTracker.track(eventType,eventName,data);
    },
    people: {
      set: (props) => {
        MixPanelTracker.setPeople(props);
      },
    },

}







