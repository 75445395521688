
import React, { useContext } from 'react'
import ProductCard from './ProductCard'
import { ChooseTreatmentContext, Product, getMinPricePerProduct, getMinPricePerQuantity } from '../FreeDosageLayout'
import ProductSearch from './ProductSearch'
import ProductFiltersControl from './ProductFiltersControl'
import { CatalogSettingContext } from '../../ChooseTreatmentRouter'
import ERezeptDisclaimer from '../../components/ERezeptDisclaimer'

interface IProductSelectionPanelProps {
  products: Product[],
  languageID: string
}

const ProductSelectionPanel: React.FC<IProductSelectionPanelProps> = ({ products, languageID }) => {

  const chooseTreatmentContext = useContext(ChooseTreatmentContext);
  const routerContext = useContext(CatalogSettingContext) as any;
  const showERezeptDisclaimer = routerContext?.showERezeptDisclaimer;
  const filteredProducts = chooseTreatmentContext?.filteredProducts || products;

  return (
    <div className='flex flex-col gap-5'>
      <ProductSearch />
      <div id='control_panel' className='flex flex-col-reverse gap-[1px] md:flex-row md:gap-0 '>
        <div id="results-count" className='mt-5 md:mt-0 flex items-center justify-center w-full text-[#0D2C54]'>
          Verfügbar: ({chooseTreatmentContext?.filteredProducts.length} {chooseTreatmentContext?.filteredProducts.length == 1 ? 'Produkt' : 'Produkte'})
        </div>
        <ProductFiltersControl />
      </div>

      {
        showERezeptDisclaimer ?
        <div id='significance_above' className='md:pr-7 mb-2 md:hidden'>
        {/* uncomment this line once erezept is ready */}
        <ERezeptDisclaimer languageID={languageID} />
        </div>
        :
        null
      }

      <div className='flex flex-col gap-5 pb-9  md:overflow-y-scroll md:max-h-[600px]'>
        {
          filteredProducts.map((product, idx) => {
            const minPricePerQuantity = getMinPricePerQuantity(product);
            const minPricePerProduct = getMinPricePerProduct(product);

            const priceString = ['gram'].includes(product.quantity_type) ? minPricePerQuantity : minPricePerProduct

            return <ProductCard key={idx} priceString={priceString} title={product.title} dosage={product.dosage_str} inStock={product.in_stock} productID={product.product_id} additionalData={product.additional_data} quantityType={product.quantity_type}/>
          })
        }
      </div>
    </div>
  )
}

export default ProductSelectionPanel
