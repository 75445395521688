import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import HeaderController from "../../components/Headers/HeaderController";
import "./MyAccountLayout.css";
import MyAccountAsideBar from "./components/MyAccountAsideBar/MyAccountAsideBar";
import Footer from "../../components/Footer/Footer";
import * as translator from "../../utils/translator";
import * as http from "../../utils/http";
import LoadingModal from "../../components/Modals/LoadingModal/LoadingModal";
import ServerError from "../../components/ServerError/ServerError";
import MyAccountThanksForUpdateModal from "../../components/Modals/MyAccountThanksForUpdateModal/MyAccountThanksForUpdateModal";
import MyAccountCancelSubscriptionModal from "../../components/Modals/MyAccountCancelSubscriptionModal/MyAccountCancelSubscriptionModal";
import StickyNotification from "../../components/StickyNotification/StickyNotification";
import HeaderCMS from "../../components/Headers/HeaderCMS/HeaderCMS";
import MyAccountSkipSubscriptionModal from "../../components/Modals/MyAccountSkipSubscriptionModal/MyAccountSkipSubscriptionModal";
import MyAccountChangeSubscriptionMedicationModal from "../../components/Modals/MyAccountChangeSubscriptionMedicationModal/MyAccountChangeSubscriptionMedicationModal";
import PharmacySelectionConfirmationModal from "../../components/Modals/PharmacySelectionConfirmationModal/PharmacySelectionConfirmationModal";

const MyAccountLayout = () => {
  const { country } = useParams();
  const [error, setError] = useState<any>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeMenuLinkId, setActiveMenuLinkId] = useState<string>("my-orders");
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [myAccountData, setMyAccountData] = useState<any>(null);
  const [asideBarData, setAsideBarData] = useState<any>(null);
  const [currentGlobalModalName, setCurrentGlobalModalName] = useState<string | null | Object>(null);
  const [stickyNotificationDetails, setStickyNotificationDetails] = useState({
    show: false,
    title: "",
    content: "",
    level: "",
    showInfoIcon: true,
    refresh: false,
  });

  const renderModal = (modalData: any) => {
    let modalName
    modalData.type ? modalName = modalData.type : modalName = modalData
    switch (modalName) {
      case "CANCEL_SUBSCRIPTION":
        return (
          <MyAccountCancelSubscriptionModal
            country={country}
            refreshData={refreshData}
            setRefreshData={setRefreshData}
            language={myAccountData.locale.language_id}
            setStickyNotificationDetails={setStickyNotificationDetails}
            closeModal={() => setCurrentGlobalModalName(null)}
            setCurrentGlobalModalName={setCurrentGlobalModalName}
            allSubscriptionData={myAccountData.mySubscriptions.subscriptionData}
          />
        );
      case "THANKS_FOR_UPDATE":
        return <MyAccountThanksForUpdateModal closeModal={() => setCurrentGlobalModalName(null)} />;

      case "PHARMACY_SELECTION_CONFIRMATION":
        return (
          <PharmacySelectionConfirmationModal
            closeModal={() => setCurrentGlobalModalName(null)}
            onSubmit={modalData.onSubmit}
            selectedPharmacy={modalData.selectedPharmacy}
          />
        );

      case "SKIP_SUBSCRIPTION":
        return (
          <MyAccountSkipSubscriptionModal
            closeModal={() => setCurrentGlobalModalName(null)}
            allSubscriptionData={myAccountData.mySubscriptions.subscriptionData}
            modalData={modalData.data}
            setStickyNotificationDetails={setStickyNotificationDetails}
            setRefreshData={setRefreshData}
            language={myAccountData.locale.language_id}
          />
        );
      case "CHANGE_SUBSCRIPTION_MEDICATION":
        return (
          <MyAccountChangeSubscriptionMedicationModal
            setCurrentGlobalModalName={setCurrentGlobalModalName}
            allSubscriptionData={myAccountData.mySubscriptions.subscriptionData}
            modalData={modalData.data}
            setStickyNotificationDetails={setStickyNotificationDetails}
            setRefreshData={setRefreshData}
            refreshData={refreshData}
            language={myAccountData.locale.language_id}
            country={country}
          />
        );
      default:
        return null; // Return null instead of empty fragment
    }
  };

  useEffect(() => {
    http
      .getFromServer(`${country}/account`, [http.MIDDLEWARES.CUSTOMER_ID])
      .then((response) => {
        setMyAccountData(response.data);
      })
      .catch((error) => {
        console.error("Error in MyAccountLayout: ", error);
        setError(500);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [refreshData]);


  useEffect(() => {
    http
      .getFromServer(`${country}/account/asidebar`, [http.MIDDLEWARES.CUSTOMER_ID])
      .then((response) => {
        setAsideBarData(response.data);
      })
      .catch((error) => {
        console.error("Error in MyAccountLayout: ", error);
        setError(500);
      });
  }, []);


  return (
    <>
      {loading && <LoadingModal />}

      <StickyNotification
        showNotification={stickyNotificationDetails.show}
        title={stickyNotificationDetails.title}
        onClick={() => setStickyNotificationDetails((currentDetails: any) => { return { ...currentDetails, show: false } })}
        content={stickyNotificationDetails.content}
        level={stickyNotificationDetails.level}
        showInfoIcon={stickyNotificationDetails.showInfoIcon}
        refresh={stickyNotificationDetails.refresh}
      />


      {asideBarData && myAccountData && country &&
        <main className="account-page">
          {currentGlobalModalName != null && renderModal(currentGlobalModalName)}
          <HeaderCMS country={country} refreshData={refreshData}

          />

          <div className="account-container">
            <div className="account-asidebar-and-content">
              <MyAccountAsideBar
                asideBarData={asideBarData}
                activeMenuLinkId={activeMenuLinkId}
                myAccountData={myAccountData}
              />

              <Outlet
                context={{
                  activeMenuLinkId,
                  setActiveMenuLinkId,
                  refreshData,
                  setRefreshData,
                  myAccountData,
                  //TODO STATE:
                  country,
                  setCurrentGlobalModalName,
                  setStickyNotificationDetails,
                  setError,
                  language: myAccountData.locale.language_id,
                }}
              />
            </div>
          </div>

          <Footer
            showDiscountDisclaimer={activeMenuLinkId === "my-orders"}
            language={translator.getLanguageByCountry(country)}
            page="MyAccount" />

        </main>
      }

      {error && !loading &&
        <>
          <HeaderController
            language={country}
            stage="MyAccount"
            showSteps={false}
          />
          <ServerError status={error} />
        </>
      }

    </>
  );
};

export default MyAccountLayout;
