import { useEffect, useState } from "react";
import "./InputField.css";
import PlacesAutocomplete from "react-places-autocomplete";

export default function GoogleApiInputField({
  type = "text",
  placeholder,
  disabled = false,
  id = "",
  mandatory,
  value = "",
  onChange,
  language,
  errorMsg = "",
  country,
  touched = false,
  onBlur = () => {},
  addressComponentIndex,
  addressType
}) {

  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleFocus = () => {
    setIsInputFocused(true);
  };

  const handleBlur = () => {
    setIsInputFocused(!!value || false);
    onBlur();
  };

  useEffect(() => {
    setIsInputFocused(!!value || isInputFocused);
  }, [value]);
  
  const searchOptions = {
    componentRestrictions: { country },
    fields: ["address_components", "geometry", "icon", "name"],
    strictBounds: false,
    types: [addressType],
  };

  // Get google error log
  const onError = (status, clearSuggestions) => {

    if (status !== "ZERO_RESULTS"){
      console.error("Google Maps API returned error with status: " + status);
    }
    clearSuggestions();
  };

  
  const onSelect = (address,a) => {    
    onChange(parseAddressComponent(address));
  };

  const parseAddressComponent = (address) => {    
    return address.split(",")[0];    
  }

  return (
    <div className={"inputfield-container"}>
      <PlacesAutocomplete
        value={value || ""}
        onChange={onChange}
        onSelect={onSelect}
        onError={onError}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <div>
              <label className="inputfield-label-placeholder">
                <span className={isInputFocused ? "inputfield-placeholder-focused" : "inputfield-placeholder"}>
                  {placeholder}{" "}
                  <span className="credential-input-mandatory-astrix">
                    {" "}
                    {mandatory ? "*" : null}{" "}
                  </span>
                </span>
              </label>

              <input
                id={id || null}
                value={value}
                className={
                  errorMsg.length === 0
                    ? "inputfield-input"
                    : "inputfield-input-error"
                }
                type={"text"}
                {...getInputProps({
                  placeholder: "",
                })}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />

              <label className="inputfield-error-label">
                {errorMsg.length === 0 ? null : (
                  <span className="inputfield-error-message"> {errorMsg} </span>
                )}
              </label>
            </div>

            <div className="google-api-suggestions-container">
              {loading && <div>Loading...</div>}
              {/* Filtering suggestions array to prevent duplicates of street and numbers from different cities,
                Filter checks for address first appearance in array
              */}
              {suggestions
                .map((suggestion) => parseAddressComponent(suggestion.description))
                .filter((address, index, self) => self.indexOf(address) === index)
                .map((address) => {
                  const { key, ...props } = getSuggestionItemProps({ description: address });
                  return (
                    <div
                      key={address}  
                      className="google-api-suggestion"
                      {...props}  
                    >
                      <span>{address}</span>
                    </div>
                  );
                })
              }
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
}
