import React, { useEffect, useState } from "react";
import "./InputField.css";
import EyeOff from "../../icons/EyeOff.svg";
import EyeOn from "../../icons/EyeOn.svg";
import Lock from "../../icons/Lock.svg";

export default function InputField({
  type = "text",
  placeholder,
  disabled = false,
  id = "",
  mandatory,
  value,
  language,
  onChange,
  onBlur = () => { },
  touched = false,
  errorMsg = "",
}) {
  const [isVisible, setIsVisible] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleFocus = () => {
    setIsInputFocused(true);
  };

  const handleBlur = () => {
    setIsInputFocused(value || false);
    onBlur();
  };

  const handleOnChange = (event) => {
    onChange(event.target.value);
  };

  useEffect(() => {
    setIsInputFocused(value || isInputFocused);
  }, [value]);



  return (
    <>
      {disabled ? (
        <div id={`${id}-container`} className={"inputfield-container-disabled"}>
          <label className="inputfield-label-placeholder">
            <span className={isInputFocused ? "inputfield-placeholder-focused" : "inputfield-placeholder"}>
              {placeholder}
              <span className="credential-input-mandatory-astrix">
                {mandatory ? "*" : null}
              </span>
            </span>
          </label>

          <input
            onBlur={handleBlur}
            value={value}
            disabled={true}
            className="inputfield-input-disabled"
            type={type}
          />
          <img src={Lock} alt="lock" />
        </div>
      ) : (
        <div id={`${id}-container`} className={"inputfield-container"}>
          <label className="inputfield-label-placeholder">
            <span className={isInputFocused ? "inputfield-placeholder-focused" : "inputfield-placeholder"}>
              {placeholder}
              <span className="credential-input-mandatory-astrix">
                {mandatory ? "*" : null}
              </span>
            </span>
          </label>
          <input
            id={id || null}
            onBlur={handleBlur}
            defaultValue={value}
            className={
              errorMsg.length === 0 || !touched
                ? "inputfield-input"
                : "inputfield-input-error"
            }
            onChange={handleOnChange}
            type={isVisible ? "text" : type}
            onFocus={handleFocus}
          />

          {type === "password" && (
            <img
              src={isVisible ? EyeOn : EyeOff}
              alt="show-hide-password"
              title={isVisible ? "Click to hide password" : "Click to show password"}
              className="inputfield-visibility-icon"
              onClick={() => setIsVisible(!isVisible)}
            />
          )}

          <label className="inputfield-error-label">
            {errorMsg.length === 0 || !touched ? null : (
              <p className="inputfield-error-message"> {errorMsg} </p>
            )}
          </label>
        </div>
      )}
    </>
  );
}
