import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import MyAccountContentHeader from "../MyAccountContentHeader/MyAccountContentHeader";
import Conversation from "../Conversation/Conversation";
import * as translator from "../../../../utils/translator";
import HistoricalConverstaionsHeader from "../HistoricalConverstaionsHeader";

type ConversationMention = {
  mention_type: string;
  mention_id: number;
  mention_text: string;
};

const MyAccountAskDoctor = () => {
  const { setActiveMenuLinkId, myAccountData, language } = useOutletContext<any>();
  const [shownHistoricalConversationIndex, setShownHistoricalConversationIndex] = useState<number | null>(null);

  useEffect(() => {
    setActiveMenuLinkId("ask-a-doctor");
  }, []);

  const activeConversation = myAccountData.askADoctor.conversations.active;
  const historicalsConversations = myAccountData.askADoctor.conversations.historicals;

  const handleHistoricalConversationHeaderOnClick = (conversationIndex:number) => {
    if (shownHistoricalConversationIndex === conversationIndex) {
      setShownHistoricalConversationIndex(null);
    } else {
      setShownHistoricalConversationIndex(conversationIndex);
    }
  }


  const getConversationMentions = (messages: any[]): any => {
    // initial means mentions that were already in the conversation and came from the server
    const initialConversationProductsMentions: ConversationMention[] = [];
    const initialConversationOrdersMentions: ConversationMention[] = [];
    const initialConversationAttachmentsMentions: ConversationMention[] = [];

    for (const message of messages) {
      for (const mention of message.mentions) {
        if (mention.mention_type.includes("Catalog")) {
          initialConversationProductsMentions.push(mention);
        } else if (mention.mention_type.includes("Order")) {
          initialConversationOrdersMentions.push(mention);
        }
      }

      for (const attachment of message.attachments) {
        if (attachment) {
          initialConversationAttachmentsMentions.push(attachment);
        }
      }
    }

    return {
      initialConversationProductsMentions,
      initialConversationOrdersMentions,
      initialConversationAttachmentsMentions,
    };
  };

  return (
    <>
      <div className="account-content">
        <MyAccountContentHeader
         title={translator.translate(language, "my_account", 'AskADoctor/title')}
         description={translator.translate(language, "my_account", 'AskADoctor/subTitle')}
        />

        {/* Render the only active conversation */}
        <Conversation
          key={0}
          conversationMessages={activeConversation} //only 1 active
          initialConversationProductsMentions={getConversationMentions(activeConversation).initialConversationProductsMentions}
          initialConversationOrdersMentions={getConversationMentions(activeConversation).initialConversationOrdersMentions}
          initialConversationAttachmentsMentions={getConversationMentions(activeConversation).initialConversationAttachmentsMentions}
          searchProducts={myAccountData.askADoctor.searchProductMentions}
          searchOrders={myAccountData.askADoctor.searchOrderMentions}
          isActive={true}
        />
        {/* End of ActiveConversation component */}

        {/* Render the historical conversations */}
        {historicalsConversations.map((historicalConversation: any, index: number) => {

          let conversationID = historicalConversation[0].conversation_id;
          //get the unread doctor messages amount
          let notifcationsAmount = historicalConversation.filter((message:any) => message.read === 0 && message.user_type?.includes("doctor")).length

          return (
            <div className="historical-conversation-accordion" key={index}>
              <HistoricalConverstaionsHeader
                onClick={() => handleHistoricalConversationHeaderOnClick(index)}
                postTime={historicalConversation[0].post_time} //when the first message created
                notificationsAmount={notifcationsAmount}
                conversationID={conversationID}
            />
            

              {shownHistoricalConversationIndex === index &&
                <Conversation
                  key={index}
                  conversationMessages={historicalConversation}
                  initialConversationProductsMentions={getConversationMentions(historicalConversation).initialConversationProductsMentions}
                  initialConversationOrdersMentions={getConversationMentions(historicalConversation).initialConversationOrdersMentions}
                  initialConversationAttachmentsMentions={getConversationMentions(historicalConversation).initialConversationAttachmentsMentions}
                  searchProducts={myAccountData.askADoctor.searchProductMentions}
                  searchOrders={myAccountData.askADoctor.searchOrderMentions}
                  isActive={false}
                />
              }
            </div>
          )
        })}
        {/* End of historical conversations */}
      </div>
    </>
  );
};

export default MyAccountAskDoctor;
