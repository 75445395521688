import React from 'react'
import * as http from "../../../utils/http"
import {useOutletContext} from "react-router-dom";
import BadgeNotification from './BadgeNotification/BadgeNotification';

interface HistoricalConverstaionsHeaderProps{
    onClick: ()=> void;
    postTime:string //the time that the conversation created
    notificationsAmount:number;
    conversationID:number;
}

const HistoricalConverstaionsHeader:React.FC<HistoricalConverstaionsHeaderProps> = ({onClick,postTime,notificationsAmount,conversationID}) => {
    const { setRefreshData} = useOutletContext<any>();

  const markAsRead = () => {
    //When showing the historical (clicking on this header),notifications need to be resetted
    if(notificationsAmount > 0){
        http
        .postToServer(`account/notifications/mark-as-read`,{conversationID},[http.MIDDLEWARES.CUSTOMER_ID])
        .then((response) => {
          setRefreshData((current:any) => !current);
        })
        .catch((error) => {
        });
    } 
  }

  return (
    <div className="historical-conversation-title"
    onClick={() => {onClick(); markAsRead()}}
    >
    <img src={require("../../../icons/Message.svg").default} alt="message" />
    <span>{postTime}</span>
    <BadgeNotification amount={notificationsAmount}
    />
  </div>
  )
}

export default HistoricalConverstaionsHeader