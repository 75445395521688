import "./WireDetailsWidget.css"

const WireDetailsWidget = ({ bankDetailsString, orderPrice, currencySign, hashID, language = 'de' }:
    { bankDetailsString:any, orderPrice:number, currencySign:string|null, hashID:string, language:string }) => {

    const bankDetails = JSON.parse(bankDetailsString)

    return (
        <div className="WireDetailsWidget-container">
            <p className="wire-title">Anleitung für die Banküberweisung:</p>

            <div className="wire-bank-details-container">
                <ul className="wire-bank-details">


                    <li className="wire-bank-detail">
                        <p className="bank-field">Kontoinhaber:</p>
                    </li>

                    <li className="wire-bank-detail">
                        <p className="bank-field">Bank:</p>
                    </li>

                    <li className="wire-bank-detail">
                        <p className="bank-field">IBAN:</p>
                    </li>

                    <li className="wire-bank-detail">
                        <p className="bank-field">SWIFT-Code:</p>
                    </li>

                    <li className="wire-bank-detail">
                        <p className="bank-field">Verwendungszweck:</p>
                    </li>

                    <li className="wire-bank-detail">
                        <p className="bank-field">Betrag:</p>
                    </li>
                </ul>

                <ul className="wire-bank-values">
                    <li className="wire-bank-detail">
                        <p className="bank-value">{bankDetails.account_name}</p>
                    </li>

                    <li className="wire-bank-detail">
                        <p className="bank-value">{bankDetails.bank_name}</p>
                    </li>

                    <li className="wire-bank-detail">
                        <p className="bank-value">{bankDetails.iban}</p>
                    </li>

                    <li className="wire-bank-detail">
                        <p className="bank-value">{bankDetails.swift}</p>
                    </li>

                    <li className="wire-bank-detail">
                        <p className="bank-value">{hashID}</p>
                    </li>

                    <li className="wire-bank-detail">
                        <p className="bank-value">{orderPrice.toFixed(2) + ' ' + currencySign}</p>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default WireDetailsWidget