import React, { useState, useRef } from 'react';
import useAutosizeTextArea from '../../customHooks/useAutosizeTextArea';
import AutoCompletionMenu from '../AutoCompletionMenu/AutoCompletionMenu';
import * as translator from "../../../../utils/translator";
import './AskADoctorInputField.css';
import { useOutletContext } from "react-router-dom";

interface AskADoctorInputFieldProps {
  value: string;
  onChange: (text: string) => void;
  onSend: (files: any) => void;
  showAutoComplete: boolean;
  autoCompleteOptions: any;
  onAutoCompletionSelect: (mention: ConversationMention) => void;
  prefixInputState: string;
}

interface ConversationMention {
  mention_type: string;
  mention_id: number;
  mention_text: string;
  mention_link?: string;
}

type FileData = {
  binary_data: ArrayBuffer,
  filename: string,
  type: string,
  size: number
}

const AskADoctorInputField: React.FC<AskADoctorInputFieldProps> = ({
  value,
  onAutoCompletionSelect,
  onChange,
  onSend,
  showAutoComplete,
  autoCompleteOptions,
  prefixInputState,

}) => {

  const { language } = useOutletContext<any>();
  const [files, setFiles] = useState<Array<FileData>>([]);
  const conversationFileUpload = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);


  useAutosizeTextArea(textareaRef.current, value);
  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (!selectedFiles) {
      return; // No files selected, return early
    }

    const newFilesArray = Array.from(selectedFiles);
    if (files.length < 5) {
      const processedFiles = await Promise.all(
        newFilesArray.map((file) => {
          return new Promise<FileData>((resolve) => {
            const fileReader = new FileReader();
            fileReader.onload = () => {
              const fileBuffer = fileReader.result as ArrayBuffer;
              resolve({
                binary_data: fileBuffer,
                filename: file.name,
                type: file.type,
                size: file.size
              });
            };
            fileReader.readAsArrayBuffer(file);
          });
        })
      );

      setFiles((prevFiles: Array<FileData>) => (prevFiles ? [...prevFiles, ...processedFiles] : processedFiles));
    }
  };

  const handleLabelClick = () => {
    conversationFileUpload.current?.click();
  };

  const removeFile = (index: number) => {
    setFiles((prevFiles: Array<FileData>) => {
      if (!prevFiles) {
        return [];
      }
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  return (
    <>
      <div className="ask-doctor-input-container">
        <label htmlFor="attachment-upload" className="attachment-label" onClick={handleLabelClick}>
          <img className="attachment-icon" src={require('../../../../icons/Paperclip-Green.svg').default} alt="attachment" />
          <input
            type="file"
            id="conversation-file-upload"
            className="file-upload-input"
            onChange={handleFileUpload}
            ref={conversationFileUpload}
            style={{ display: 'none' }}
          />
        </label>

        <textarea
          ref={textareaRef}
          required
          id="ask-a-question-input"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          className="ask-a-question-input"
          rows={1}
          placeholder={translator.translate(language, "my_account", 'AskADoctor/input_placeholder')}
        />

        {showAutoComplete ?
          <AutoCompletionMenu
            mentions={autoCompleteOptions}
            onAutoCompletionSelect={(mention: ConversationMention) => onAutoCompletionSelect(mention)}
            prefixInputState={prefixInputState}
          />
          : null
        }

        <img onClick={() => {
          if(value){
            onSend(files); 
            setFiles([])
          }
        }}
          className="send-icon"
          src={require('../../../../icons/Send-White.svg').default} 
          alt="send" />
      </div>

      {files && files.length ? (
        <div className="ask-doctor-input-selected-files">
          {files.map((file, index) => (
            <div key={index} className="selected-file" onClick={() => removeFile(index)}>
              <span className="selected-file-name">{file.filename}</span>
              <span className="selected-file-size">({file.binary_data.byteLength} B)</span>
              <img src={require('../../../../icons/X-Green.svg').default} alt="X" />
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default AskADoctorInputField;
