/* MIXPANEL TRACKER   */

import mixpanel from "mixpanel-browser";
import { clientConfig } from "../config";
import * as http from "../utils/http";

const ignoreList = ["Placed Order", "ViewThankyouPage"]; //dont send those events to mp, some are already sent through PageTracker

const initLastTouchProperties = () => {
  const utmParametersMap = {
    tr: "Click ID",
    utm_source: "UTM Source",
    utm_medium: "UTM Medium",
    utm_campaign: "UTM Campaign",
    cmp_id: "Campaign id",
    grp_id: "Ad group id",
    utm_content: "Utm Content",
    ad_id: "Ad id",
    utm_term: "Utm Term",
  };

  // init utms with empty string, in order to lock the first attributions
  let utms = {};
  let utmsLastTouch = {};
  for (const [value] of Object.entries(utmParametersMap)) {
    utms[value] = "";
    utmsLastTouch[value] = "";
  }

  try {
    let trackingData = window.eventsTracking.getData();
    let lastArrival = trackingData.arrivals[trackingData.arrivals.length - 1];

    let url;

    // in case url starts only with ? -> in case of short arrival (iphone prevent localstorage cross subdomains)
    if (lastArrival.url.startsWith("?")) {
      url = new URL("http://a.com" + lastArrival.url);
    } else {
      url = new URL(lastArrival.url);
    }

    let currAfValue;
    let oldAfValue;

    url.searchParams.forEach((value, key) => {
      if (key === "af") {
        currAfValue = value;
      }
      else{
        let propertyName = utmParametersMap[key];
        if (propertyName) {
          utms[propertyName] = "Affiliate " + value;
          utmsLastTouch["Last_touch " + propertyName] = value;
        }
      }
    });

    let mixpanelCookie = window.eventsStorage.getFromCookie(
      `mp_${clientConfig.MIXPANEL_TOKEN}_mixpanel`
    );

    if (mixpanelCookie) {
      let mixPanelValues = JSON.parse(mixpanelCookie);

      oldAfValue = mixPanelValues["Last_touch Affiliate ID"];
    }

    // get from server affiliate name if:
    // there is no af stored in mixpanel
    // the current af in mixpanel is different than current
    if (!oldAfValue || oldAfValue !== currAfValue) {

      //get the affiliate name from the server
      http
        .postToServer(`tracking/utms`, { trackingData: window.eventsTracking.getData() })
        .then((response) => {
          mixpanel.register({
            "Last_touch Affiliate ID Name": response.data.affiliateName,
            "Last_touch Affiliate ID" : response.data.externalID
          });
          mixpanel.register({
            "Affiliate Name": response.data.affiliateName,
            "Affiliate ID": response.data.externalID
          });

          //store also affiliates cookie for php 
          let currAffCookie = window.eventsStorage.getFromCookie('dtr_aff')

          if (!currAffCookie){
            // disabled because the php uses affiliation data by customer_affiliations table
            // window.eventsStorage.addToCookie('dtr_aff', response.data.phpAffCookie)
          }

        })
        .catch((error) => {
          // setShowStickyNotification(true);
          console.error("error get affiliateName: ", error);
        });
    }

    mixpanel.register(utmsLastTouch);
    mixpanel.register_once(utms);
  } catch (err) {
    console.error("error loading arrival Variables into Mixpanel", err);
  }
};

if (clientConfig.MIXPANEL_TOKEN) {
  mixpanel.init(clientConfig.MIXPANEL_TOKEN, {
    api_host: clientConfig.MIXPANEL_HOST,
    set_config: {
      "Content-Type": "application/json",
    },
  });
}

export const init = () => {

  if (window.eventsTracking && window.eventsTracking.getData("uid")){
    mixpanel.register({uid:window.eventsTracking.getData("uid")})
  }

  initLastTouchProperties();
};

export const identify = (uuid) => {
  clientConfig.MIXPANEL_TOKEN && mixpanel.identify(uuid);
};

export const alias = (id) => {
  clientConfig.MIXPANEL_TOKEN && mixpanel.alias(id);
};

export const superProperties = (props) => {
  clientConfig.MIXPANEL_TOKEN && mixpanel.register(props);
  window.eventsTracking.setMetaData(props)
};

export const track = (eventType, eventName, props) => {
  if (!ignoreList.includes(eventName)){
    if (!props){
      props = {}
    }
    
    // send to mixpanel
    clientConfig.MIXPANEL_TOKEN && mixpanel.track(eventName, props);

    // send to our tracking
    window.eventsTracking.track({e:eventName,p:props});

    if (eventType === "pv"){
      let pageName = eventName.replace("View ","").replace(" Page","")
      clientConfig.MIXPANEL_TOKEN && mixpanel.track_pageview({...props, "page_type": pageName});
      window.eventsTracking.track({e:'pv',p:{...props,"page_type": pageName}});
    }
  }
};

export const setPeople = (props) => {
  clientConfig.MIXPANEL_TOKEN && mixpanel.people.set(props);
};
