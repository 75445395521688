import { createContext, useEffect, useState } from "react";
import { clientConfig } from "../../config";
import "./Shipping.css";
import * as http from "../../utils/http";
import * as STATIC from "./constants";
import * as SessionStorageWrapper from "../../utils/SessionStorageWrapper";
import { useParams, useNavigate, createSearchParams } from "react-router-dom";
import { updateSubscriptionDBCart } from "../ChooseTreatmentVariants/helpers";
import HeaderController from "../../components/Headers/HeaderController";

import { clearCart, loadCart } from "../../reducers/CartOrderReducer";
import { loadCustomerFromSession } from "../../reducers/CustomerReducer";
import LoadingModal from "../../components/Modals/LoadingModal/LoadingModal";
import SubscriptionModal from "../../components/Modals/SubscriptionModal/SubscriptionModal";
import ServerError from "../../components/ServerError/ServerError";
import * as translator from "../../utils/translator";
import OrderInfoInterface from "./Components/OrderInfoInterface/OrderInfoInterface";
import { MainTracker } from "../../PixelTrackers/MainTracker";
import LayoutByCountry from "./Components/LayoutByCountry/LayoutByCountry";
import Footer from "../../components/Footer/Footer";
import StickyNotification from "../../components/StickyNotification/StickyNotification";
import useForm from "../../customHooks/useForm";
import {
  billingShippingFormInitialValues,
  packstationShippingFormInitialValues,
  personalShippingFormInitialValues,
} from "./constants";
import { loadGoogleApi, navigateBasedOnPaymentTypeID } from "./helpers";
import AddressValidationModal from "../../components/AddressValidationModal/AddressValidationModal";
import OTCAddonsModal from "../../components/Modals/OTCAddonsModal/OTCAddonsModal";
import useOTCAddons from "../../customHooks/useOTCAddons";
import ModalsWrapper from "../../components/Modals/ModalsWrapper/ModalsWrapper";
import useShipping from "./useShipping";

export const ShippingDataContext = createContext();
export const LayoutByCountryContext = createContext();

export const StickyNotificationContext = createContext();

const Shipping = () => {

  const { country, language } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const [destinationCountry, setDestinationCountry] = useState(country) /*made for the swiss feature, since country is connected to catalogs and we want to use de for the catalogs,  will be used for external apis, form and post*/
  const ec = urlParams.get('ec');

  // ---------------------------------- custom hooks and effects  -------------------------------------------------- //
  // ---------------------------------- forms declaration -------------------------------------------------- //

  //this one is not const because it may be overriden by historic
  let personalShippingFormAttributes = useForm(
    personalShippingFormInitialValues,
    language,
    destinationCountry
  );

  let packstationShippingFormAttributes = useForm(
    packstationShippingFormInitialValues,
    language,
    destinationCountry
  );

  const billingShippingFormAttributes = useForm(
    billingShippingFormInitialValues,
    language,
    destinationCountry
  );

  // --------------------------------------------------------
  const shippingGlobals = useShipping({
    country,
    language,
    personalShippingFormAttributes,
    packstationShippingFormAttributes,
    billingShippingFormAttributes,
    destinationCountry,
    setDestinationCountry
  });
  // ------------------------------------------------------------------------------------ //

  let isAddressAutoVerified = false;

  //OTC modals data
  const OTCAddonsAttributes = useOTCAddons(
    true,
    shippingGlobals.OTCAddonsProducts,
    shippingGlobals.cartID
  );

  const [onSubmitParams, setOnSubmitParams] = useState({});
  const [suggestedAddress, setSuggestedAddress] = useState("");
  const [isLoadedApi, setIsLoadedApi] = useState(false);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

  //end of form related states
  //is treatment drug an over-the-counter drug

  const [showStickyNotification, setShowStickyNotification] = useState({
    show: false,
    title: "",
    content: "",
    refresh: false,
  });

  //start of helper functions

  const handleErrorNotification = (title, content) => {
    if (!title && !content) {
      setShowStickyNotification({ show: false, title: "", content: "" });
    } else {
      setShowStickyNotification((current) => ({
        show: true,
        title,
        content,
        refresh: !current.refresh,
      }));
    }
  };

  const cancelSubscriptionFlow = () => {
    updateSubscriptionDBCart(shippingGlobals.cartID, null);
    shippingGlobals.setIsSubscriptionFlow(false);
    shippingGlobals.setSelectedPlanIndex(null);
    shippingGlobals.setSubscriptionDiscount(0);
    shippingGlobals.setShowSubscriptionPlans(false);
    shippingGlobals.setOrderWindowDetails({
      ...shippingGlobals.orderWindowDetails,
      didntChooseSubscriptionInCT: true,
    });
  };

  function navigationCallback(pathname, data = {}, queryParams) {
    navigate(pathname + "?" + createSearchParams(queryParams).toString(), {
      state: data,
    });
  }


  const handleCannabisIDValidation = () => {

    const inputValue = shippingGlobals.cannabisIDState.personalIDNumber;
    let isInputValid = false
    let errorMsg = "";
    const germanIDRegex = /^[A-Za-z0-9]{9}$/;

    if(!inputValue){
      errorMsg = "Bitte geben Sie Ihre persönliche Personalausweis-Nummer ein"
      isInputValid = false

    } else if(!germanIDRegex.test(inputValue)) {  //Regex check of german id number
      errorMsg = "Bitte geben Sie eine gültige Personalausweis-Nummer ein"
      isInputValid = false

    } else {
      isInputValid = true
    }

    shippingGlobals.setCannabisIDState((prevState) => ({
      ...prevState,
      errorMsg
    }))

    return isInputValid
  }

  //end of helper functions

  function isFormValid(isPackstationDelievery) {
    //Validation is based on which form is open, if the delivery methond is packstation, we check it only.
    // else we will check personalshippingform and billing if it's toggle close.


    //Validate CannabisId input if added to order:
    if (shippingGlobals.cannabisIDState.isCannabisIDAddedToOrder){
      const isCannabisIDValid = handleCannabisIDValidation()

      if (!isCannabisIDValid){
        //Scroll to digital-cannabis-id-widget-container
        if (shippingGlobals.cannabisIDState.widgetRef){
          shippingGlobals.cannabisIDState.widgetRef.current?.scrollIntoView({behavior: "smooth", block: "start"})
        }

        return false
      }
    }

    if (isPackstationDelievery) {
      return packstationShippingFormAttributes.validateForm();
    } else {
      if (!shippingGlobals.useAddressAsBillingToggle) {
        return (
          billingShippingFormAttributes.validateForm() &&
          personalShippingFormAttributes.validateForm()
        );
      } else {
        return personalShippingFormAttributes.validateForm();
      }
    }
  }

  const addressValidator = async (formAddress) => {
    const apiKey = clientConfig.BYTEPLANT_API_KEY;

    // checks if customer has a delivered order with the same address as in the shipping form
    if (shippingGlobals.isDelivered === true) {
      const isMatchingAddress = //question marks added for customers from the in-between versions period who'd find themselves with with past orders but no customer_addresses
        shippingGlobals.personalAddress?.address === formAddress.address &&
        shippingGlobals.personalAddress?.zip === formAddress.zip &&
        shippingGlobals.personalAddress?.city === formAddress.city;

      if (isMatchingAddress) {
        isAddressAutoVerified = true;
        return true;
      }
    }

    // skip address validation only on dev. in order to save amount of api calls
    if (clientConfig.SKIP_ADRRESS_VALIDATION){
      return true
    }

    const url = `https://api.address-validator.net/api/verify?StreetAddress=${formAddress.address}
    &City=${formAddress.city}
    &PostalCode=${formAddress.zip}
    &CountryCode=${destinationCountry}
    &APIKey=${apiKey}`;

    return fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status == "VALID") {
          isAddressAutoVerified = true;
          MainTracker.track('custom', 'Address Validation Valid', {})
          return true;
        } else if (data.status == "SUSPECT") {
          setSuggestedAddress(data);
          shippingGlobals.setShowAddressValidation("suspected");
          MainTracker.track('custom', 'Address Validation Suspected', {})
          return false;
        } else if (data.status == "INVALID") {
          setSuggestedAddress(data);
          shippingGlobals.setShowAddressValidation("invalid");
          MainTracker.track('custom', 'Address Validation Invalid', {})
          return false;
        } else {
          MainTracker.track('custom', 'Address Validation Not Opened', {})
          return true;
        }
      })
      .catch((error) => {
        console.error("Auto Address validation request failed", error);
      });
  };

  async function onSubmit(
    paymentTypeID,
    paymentType,
    shippingMethodID,
    suggestedAddress = false
  ) {
    setSubmitButtonLoading(true);
    

    let shippingFormData = {
      ...personalShippingFormAttributes.form,
      ...packstationShippingFormAttributes.form,
      ...billingShippingFormAttributes.form,
    };

    let isPackstationDelievery = false;

    if (
      shippingGlobals.selectedDeliveryMethodIndex ===
      STATIC.PACKSTATION_DELIVERY_METHOD_INDEX
    ) {
      isPackstationDelievery = true;
    }

    if (await !isFormValid(isPackstationDelievery)) {
      const invalidElements = document.getElementsByClassName('inputfield-input-error')
      if(invalidElements.length > 0){
        invalidElements[0].scrollIntoView({
          behavior: "smooth",
        });
      }
      setSubmitButtonLoading(false);
      return;
    }

    try {
      if (!isPackstationDelievery) {
        if (shippingGlobals.showAddressValidation === "") {
          setOnSubmitParams({ paymentTypeID, paymentType, shippingMethodID });
          let isAddressValid = await addressValidator(shippingFormData);
          if (isAddressValid === false) {
            return;
          }
        }
        if (suggestedAddress) {
          if (
            suggestedAddress.street &&
            suggestedAddress.streetnumber &&
            suggestedAddress.city &&
            suggestedAddress.postalcode
          ) {
            shippingFormData.address = `${suggestedAddress.street} ${suggestedAddress.streetnumber}`;
            shippingFormData.city = suggestedAddress.city;
            shippingFormData.zip = suggestedAddress.postalcode;
            isAddressAutoVerified = true;
          } else {
            console.warn(
              "Auto Address validation failed to to undefined suggestions from Byteplant's API",
              suggestedAddress
            );
          }
        }
      }
    } catch (err) {
      console.warn("Auto Address validation failed", err);
    }

    let addressType = isPackstationDelievery ? "packstation" : "regular";
    let trackingData = window.eventsTracking && window.eventsTracking.getData();

    await http
      .postToServer(
        `shipping/${country}/${language}`,
        {
          country,
          destinationCountry,
          language,
          shippingFormData,
          isAddressAutoVerified,
          addressType,
          cartID: shippingGlobals.cartID,
          trackingData,
          paymentTypeID,
          selectedDeliveryMethodID: shippingGlobals.selectedDeliveryMethodID,
          useAddressAsBilling: shippingGlobals.useAddressAsBillingToggle,
          selectedOTCAddons: OTCAddonsAttributes.formatForSubmit(),
          allowEPrescriptionOnlyFeature: shippingGlobals.allowEPrescriptionOnlyFeature,
          isCannabisIDAddedToOrder: shippingGlobals.cannabisIDState.isCannabisIDAddedToOrder,
          personalIDNumber: shippingGlobals.cannabisIDState.personalIDNumber.toUpperCase(),
        },

        [http.MIDDLEWARES.CART_ORDER, http.MIDDLEWARES.CUSTOMER_ID]
      )
      .then(async (response) => {
        sessionStorage.removeItem('cpn');
        let hashID = response.data.hashID;
        window.eventsTracking.setData("last_order", hashID);
        let thankyouHash = response.data.thankyouHash;
        const catalog = response.data.catalog;
        const customer = response.data.customer
        const bmi = Math.round((customer.weight/((customer.height/100)**2)))
        const bloodPressure = customer.blood_pressure ? customer.blood_pressure.slice(0, 3) : null
        let customerFromSession = loadCustomerFromSession();

        if (!customerFromSession) {
          // if no customer id send to registration
          navigationCallback(`/error/${language}/${country}`);
        } else {
          MainTracker.track("custom", "Placed Order", response.data);

          let sessionCart = loadCart();

          // save the last cart id for preventing bothering api if user clicks back
          SessionStorageWrapper.setItem("last_shipped_cart", sessionCart.id);
          clearCart();

            navigateBasedOnPaymentTypeID(paymentType, navigationCallback, {
            thankyouHash,
            customerData: shippingGlobals.customerData,
            order: response.data.order,
            country,
            language,
            hashID,
            catalog,
            bmi,
            bloodPressure
          });
        }
      })
      .catch((err) => {
        if (["dosage_not_in_stock", "dosage_not_in_stock_for_e_recipe", "dosage_not_in_stock_for_cod"].includes(err.response?.data.status)) {
          navigationCallback(
            `/select_treatment/${country}/${language}/${shippingGlobals.mention}`,
            { errorCode: "dosage_not_in_stock" },
            { ec: "dnis" }
          );
        } else if (err.response?.data.status === "already_subscribed") {
          navigationCallback(
            `/${country}/account/orders-subscription`,
            { errorCode: "already_subscribed" },
            { ec: "as" }
          );

          cancelSubscriptionFlow();
        } else if (err.response?.data.status === "no_stock_suitable_for_subscription") {
          cancelSubscriptionFlow();

          //update the order window
          //show sticky for error...
          handleErrorNotification(
            translator.translate(
              language,
              "default",
              "StickyNotification/danger_title"
            ),
            translator.translate(
              language,
              "default",
              "StickyNotification/product_not_in_stock_for_subscription"
            )
          );
        } else if(err.response?.data.status === "prescription_only_erecipe"){
          handleErrorNotification(
            translator.translate(
              language,
              "default",
              "StickyNotification/danger_title"
            ),
            translator.translate(
              language,
              "default",
              "StickyNotification/prescription_only_for_e-recipe"
            )
          );
        } else {
          handleErrorNotification(
            translator.translate(
              language,
              "default",
              "StickyNotification/danger_title"
            ),
            "Server Failed"
          );
        }

        console.error("error sumbit shipping", err);
      });

    setSubmitButtonLoading(false);
  }

  useEffect(() => {
    window.addEventListener("popstate", (e) => {
      e.preventDefault();
      if (shippingGlobals.catalog?.is_video_consultation) {
        navigate(
          `/questionnaire/${country}/${language}/${shippingGlobals.mention}`
        );
      } else if (urlParams.get("reorder")) {
        //Then don't do anything, go to the regular back
      } else {
        navigate(
          `/select_treatment/${country}/${language}/${shippingGlobals.mention}`
        );
      }
    });
  }, [shippingGlobals.mention]);

  useEffect(() => {
    if (country.toUpperCase() === "FR") {
      shippingGlobals.setShowSubscriptionPlans(false);
    }

    async function wrapper() {
      await loadGoogleApi(() => {
        setIsLoadedApi(true);
      });

      let sessionCart = loadCart();

      // checking if no cart.id - means we deleted the cart id
      // checking if the cart id stil exists but was already shipped ( can be set from url by main website directly to shipping )
      if (
        !sessionCart.id ||
        SessionStorageWrapper.getItem("last_shipped_cart") === sessionCart.id
      ) {
        shippingGlobals.setError(400);
        shippingGlobals.setLoading(false);
      } else {
        await shippingGlobals.getShippingPageData();
        if (sessionCart["is_otc"] === 1) {
          shippingGlobals.setIsTreatmentOTC(true);
        }
      }
    }

    wrapper();
  }, []);

  useEffect(() => {
    if (ec === 'forceRefresh') {
      handleErrorNotification(
        translator.translate(
          language,
          "default",
          "StickyNotification/cart_error_title"
        ),
        translator.translate(
          language,
          "default",
          "StickyNotification/multiple_orders_not_allowed"
        )
      );
    }
  }, []);



  return (
    <StickyNotificationContext.Provider value={{handleErrorNotification}}>
    <>
      {shippingGlobals.loading && <LoadingModal />}
      {shippingGlobals.error && (
        <>
          <HeaderController showSteps={false} stage={"Questionnaire"} />
          <ServerError status={shippingGlobals.error} />
        </>
      )}

      {!shippingGlobals.error && !shippingGlobals.loading && isLoadedApi && (
        <ShippingDataContext.Provider
          value={{
            ...shippingGlobals,
            OTCAddonsAttributes,
            catalogID: shippingGlobals.catalog?.id,
            destinationCountry,
            setDestinationCountry,
            country,
            submitButtonLoading,
            prescriptionOnly: shippingGlobals.isOnlyPrescription,
            personalShippingFormAttributes,
            packstationShippingFormAttributes,
            billingShippingFormAttributes,
          }}
        >
          <ModalsWrapper
            shouldBackgroundClickHideModal={false}
            hideModal={OTCAddonsAttributes.hideModal}
            shouldShowModal={
              OTCAddonsAttributes.shouldShowModal &&
              shippingGlobals.OTCAddonsProducts.length &&
              country.toLowerCase() != "fr" //TODO: remove it when france otc is live
            }
          >
            <OTCAddonsModal
              productItems={shippingGlobals.OTCAddonsProducts}
              title={translator.translate(language, "shipping", "OTCAddonsModal/title")}
              OTCAddonsAttributes={OTCAddonsAttributes}
              countryId={country}
            />
          </ModalsWrapper>

          <StickyNotification
            showNotification={showStickyNotification.show}
            title={showStickyNotification.title}
            onClick={(falseValue) => setShowStickyNotification(falseValue)}
            content={showStickyNotification.content}
            refresh={showStickyNotification.refresh}
          />

          {shippingGlobals.showSubscriptionModal && (
            <SubscriptionModal
              showModalCallback={(boolValue) =>
                shippingGlobals.setShowSubscriptionModal(boolValue)
              }
              language={language}
              discount={shippingGlobals.orderWindowDetails.subscriptionPlans[0].discount}
              currencySign={shippingGlobals.currencySign}
            />
          )}

          <>
            <HeaderController language={language} stage="Payment" />

            <div className="shipping-page-body">
              <div className="shipping-page-container">
                <p className="shipping-page-main-title">
                  {translator.translate(
                    language,
                    "shipping",
                    "ShippingPage/main_title"
                  )}
                </p>

                <div className="shipping-page-panels-container">
                  <div className="left-panel">
                    {
                      <LayoutByCountryContext.Provider
                        value={{
                          ...shippingGlobals,
                          onSubmit,
                        }}
                      >
                        <LayoutByCountry>
                          <OrderInfoInterface
                            orderWindowDetails={
                              shippingGlobals.orderWindowDetails
                            }
                            subscriptionDiscount={shippingGlobals.subscriptionDiscount}
                            couponDiscount={shippingGlobals.couponDiscount}
                            selectedPlanIndex={
                              shippingGlobals.selectedPlanIndex
                            }
                            showSubscriptionPlans={
                              shippingGlobals.showSubscriptionPlans
                            }
                            showCouponConfirmed={
                              shippingGlobals.showCouponConfirmed
                            }
                            showCouponInput={shippingGlobals.showCouponInput}
                            discountData={shippingGlobals.discountData}
                            updateDispatch={shippingGlobals.updateDispatch}
                            autoBonusValue={shippingGlobals.autoBonusValue}
                            countryID={country}
                            OTCAddonsAttributes={OTCAddonsAttributes}
                            isSelfPickup={shippingGlobals.selfPickupPharmacy ? true : false}

                          />
                        </LayoutByCountry>
                      </LayoutByCountryContext.Provider>
                    }
                  </div>

                  <div className="right-panel">
                    <LayoutByCountryContext.Provider value={{
                      ...shippingGlobals,
                      onSubmit
                    }}>
                      <OrderInfoInterface
                        orderWindowDetails={shippingGlobals.orderWindowDetails}
                        subscriptionDiscount={shippingGlobals.subscriptionDiscount}
                        couponDiscount={shippingGlobals.couponDiscount}
                        selectedPlanIndex={shippingGlobals.selectedPlanIndex}
                        showSubscriptionPlans={shippingGlobals.showSubscriptionPlans}
                        showCouponConfirmed={shippingGlobals.showCouponConfirmed}
                        showCouponInput={shippingGlobals.showCouponInput}
                        discountData={shippingGlobals.discountData}
                        updateDispatch={shippingGlobals.updateDispatch}
                        countryID={country}
                        autoBonusValue={shippingGlobals.autoBonusValue}
                        OTCAddonsAttributes={OTCAddonsAttributes}
                        isSelfPickup={shippingGlobals.selfPickupPharmacy}

                      />
                    </LayoutByCountryContext.Provider>
                  </div>
                </div>
              </div>
            </div>

            <Footer page="Shipping" language={language} />
            
            {["invalid", "suspected"].includes(shippingGlobals.showAddressValidation) && (
              <AddressValidationModal
                language={language}
                suggestionType={shippingGlobals.showAddressValidation}
                onSubmitParams={onSubmitParams}
                onSubmit={onSubmit}
                setShowAddressValidation={(newValue) => {
                  shippingGlobals.setShowAddressValidation(newValue);
                }}
                setSubmitButtonLoading={(newValue) => {
                  setSubmitButtonLoading(newValue);
                }}
                suggestedAddress={suggestedAddress}
              />
            )}
          </>
        </ShippingDataContext.Provider>
      )}
    </>
    </StickyNotificationContext.Provider>
  )
};

export default Shipping;
